import { makeStyles, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';
import React, { useEffect, useState } from 'react';
import { YAxis, Tooltip, XAxis, ResponsiveContainer, AreaChart, Area } from 'recharts';
import * as moment from 'moment';

const GraphComponent = (data, parentGraphData) => {  
    
    const [graphData, setGraphData] = useState(parentGraphData);

    const WhiteTextTypography = withStyles({
        root: {
          color: "#544e00",
          textShadow: "2px 2px #ffffff"
        },
        // sub: {
        //     color: "#544e00",
        //     textShadow: "1px 1px #ffffff"
        // }
    })(Typography);
    
    makeStyles({
        root: {
          maxWidth: 245,
          backgroundColor: "rgb(0, 0, 0)",
          margin: "40px auto",
        },
        paper: {
            // backgroundColor: "rgb(250, 250, 120)",
            margin: "10px 10px",
            padding: "3px"
        },
        "recharts-default-tooltip": {
            backgroundColor: "rgb(0, 0, 0)"
          }
    });

    useEffect(() => {
        fetch('./production.json').then(
            (res) => {
                return res.json();
            }
        ).then(
            (result => {
                setGraphData(result);
            })
        )
    }, []);

    return (
        <div className='centerText'>
            <WhiteTextTypography align="center" variant="subtitle2" color="inherit">
                Senaste 6 dagars produktion
            </WhiteTextTypography>
            <ResponsiveContainer width="95%" height={150}>
                <AreaChart data={graphData} >
                    <defs>
                    <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
                        <stop offset="10%" stopColor="#fff244" stopOpacity={0.8}/>
                        <stop offset="95%" stopColor="#ff0092" stopOpacity={0}/>
                    </linearGradient>
                    </defs>
                    {/* <Bar dataKey="mean" fill="#544e00" name={"Watt"} /> */}
                    <XAxis dataKey="time" tickFormatter={(t) => moment(t).format('DD/MM')} />
                    <YAxis />
                    <Tooltip cursor={false} contentStyle={ {backgroundColor: '#FFFFFF'} } labelFormatter={(t) => moment(t).format('HH:mm DD/MM')} formatter={(t) => { return [parseFloat(t).toFixed(0), "Watt"]}} />
                    {/* <Tooltip content={<GraphTooltip />} /> */}
                    <Area strokeWidth={3} type="monotone" dataKey="mean" stroke="#3d4316" fillOpacity={4} fill="url(#colorUv)" />
                </AreaChart>
            </ResponsiveContainer>
        </div>
    )
}


export default GraphComponent;