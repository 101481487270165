import { Button, Link, withStyles } from '@material-ui/core';
import React from 'react';
import YouTube from '@material-ui/icons/YouTube';
import { red } from '@material-ui/core/colors';


const StyledButton = withStyles({
    root: {
        // background: 'linear-gradient(45deg, #f7e15b 30%, #f2df66 90%)',
        borderRadius: 3,
        border: 3,
        color: 'black',
        height: 48,
        padding: '0 30px',
        boxShadow: '0 3px 5px 2px rgba(237, 216, 78, .3)',
    },
    label: {
      textTransform: 'none',
    },
  })(Button);

const ReklamComponent = () => {
        
    return <div className="centerText">
        <StyledButton>
            <Link href="https://www.youtube.com/channel/UCVca2QZ_oJiWv9R5bBdpweA" color="inherit">För ett smartare hem <YouTube  style={{ color: red[500] }} /></Link>
        </StyledButton>
    </div>
}

export default ReklamComponent;