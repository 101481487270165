import { Box, Card, CardActionArea, CardMedia, makeStyles } from '@material-ui/core';
import React from 'react';

const PanelerComponent = () => {
        
    const useStyles = makeStyles({
        root: {
          maxWidth: 345,
          backgroundColor: "rgb(250, 250, 120)",
          margin: "40px auto",
        },
        paper: {
            backgroundColor: "rgb(250, 250, 120)",
            margin: "10px 10px",
            padding: "3px"
        }
      });
    const classes = useStyles();

    return <Card className={classes.root}>
        <CardActionArea>
            {/* <Tooltip title={longText}>
                </Tooltip> */}
                <CardMedia
                    component="img"
                    image="solpaneler-norr.png">
                </CardMedia>
                <Box className={classes.paper}>
                    32 st Longi Mono 365 W, 11,68kW. 20° takvinkel i Sollentuna, Stockholm. Goodwe GW10KN-DT växelriktare.
                </Box>
        </CardActionArea>
    </Card>
}

export default PanelerComponent;