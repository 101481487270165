import React, { useEffect, useRef, useState } from 'react'
import './App.css';
import ImageLogo from './ImageLogo';
import PowerProduction from './components/power/PowerProduction';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import { createTheme, makeStyles, MuiThemeProvider, withStyles } from '@material-ui/core/styles';
import ReklamComponent from './components/reklam';
import PanelerComponent from './components/paneler';
import PowerStatistics from './components/power/PowerStatistics';
import ReactGA from 'react-ga';
import YoutubeComponent from './components/youtube';

ReactGA.initialize('UA-263108-6', { debug: false });


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fixedHeight: {
    height: 240,
  },
}));

let themeSun = createTheme({
  palette: {
    background: {
      default: "rgb(252, 252, 126)",
    },
  },
  overrides: {
    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundImage: 'linear-gradient(to top right, #b49803 , #fcfc7e)'
        }
      }
    },
  }
});

themeSun = createTheme(themeSun, {
  components: {
    MuiTab: {
      styleOverrides: {
        indicator: {
          backgroundColor: "0000ff",
          height: 10,
        },
        root:{
          "&.Mui-selected": {
            backgroundColor: "#ff0000",
            color: "#00FF00",
            borderRadius: "25px"
          },
        },
      },
    },
  },

});

const WhiteTextTypography = withStyles({
  root: {
    color: "#544e00",
    textShadow: "1px 1px #ffffff",
  }
})(Typography);



function App() {
  const classes = useStyles();

  const webSocket = useRef(null);

  const [power, setPower] = useState("-");
  const [monthGeneration, setMonthGeneration] = useState(0);
  const [todayGeneration, setTodayGeneration] = useState(0);
  const [updated, setUpdated] = useState(Date.now());
  const [isLive, setIsLive] = useState(false);
  const [graphData, setGraphData] = useState([]);
  const [usageData, setUsageData] = useState([]);
  const handleSetGraph = () => setGraphData(graphData);
  
  useEffect(() => {
    ReactGA.pageview(window.location.pathname + window.location.search);

    fetch('./usage.json').then(
        (res) => {
            return res.json();
        }
    ).then(
        (result => {
            // console.log(result);
            setUsageData(result.avg);
        })
    )

    webSocket.current = new WebSocket("wss://9yc7fdvxha.execute-api.eu-north-1.amazonaws.com/prod");
    webSocket.current.onopen = () => {
      webSocket.current.send("powerreading");
    };
    webSocket.current.onmessage = (message) => {
      let jsonInput = JSON.parse(message.data);      
      setPower(jsonInput.pac);
      setMonthGeneration(jsonInput.month_generation);
      setTodayGeneration(jsonInput.today_generation);
      setUpdated(Date.now());
      setIsLive(true);
    };
    webSocket.current.onclose = () => {
      setIsLive(false);
    };
    return () => webSocket.current.close();
  }, []);

  return (
    <MuiThemeProvider theme={themeSun}>
      <CssBaseline />
      <WhiteTextTypography align="center" component="h1" variant="h5" color="inherit" noWrap className={classes.title}>
        <p>AJJM Solcellsproduktion</p>
      </WhiteTextTypography>
      {/* <DisruptionComponent /> */}
      <ImageLogo power={power} />
      <PowerProduction production={power} isLive={isLive} updated={updated} />
      <PowerStatistics 
        monthGeneration={monthGeneration} 
        todayGeneration={todayGeneration}
        usage={usageData}
        graphData={graphData}
        updateGraph={handleSetGraph}
        production={power}
        />
      {/* <GraphComponent graphData={graphData} updateGraph={handleSetGraph} /> */}
      <ReklamComponent />
      <PanelerComponent />
      <YoutubeComponent />
    </MuiThemeProvider>
  );
}

export default App;
