import React from 'react';

const ImageLogo = ({ power }) => {

    let imgName = "nosun.png";

    if (power > 0 && power <= 1000) {
        imgName = "sun-0.png";
    } else if (power > 1000 && power <= 2000) {
        imgName = "sun-1.png";
    } else if (power > 2000 && power <= 4000) {
        imgName = "sun-2.png";
    } else if (power > 4000 && power <= 6000) {
        imgName = "sun-3.png";
    } else if (power > 6000 && power <= 8000) {
        imgName = "sun-4.png";
    } else if (power > 8000 ) {
        imgName = "sun-5.png";
    }

    return <div className="sun">
        <img alt="sun" src={imgName} ></img>
    </div>
}

export default ImageLogo;