import { Typography, Box, withStyles } from '@material-ui/core';
import AnimatedNumber from "animated-number-react";

const WhiteTextTypography = withStyles({
    root: {
      color: "#544e00",
      textShadow: "2px 2px #ffffff"
    },
    // sub: {
    //     color: "#544e00",
    //     textShadow: "1px 1px #ffffff"
    // }
  })(Typography);


let formatValue = 0;


const PowerProduction = ({production, isLive}) => {

    formatValue = (value) => {
        let returnString = "";
        if (isLive) {
            returnString = Number(value).toFixed(0) + " W";
        } else {
            returnString = "- ";
        }
        return returnString;
    };

    return <div className="powerDisplay">
        <div className="powerValue">
            <div className={ isLive ? 'isonline' : 'isoffline'  }>&#x25cf;LIVE</div>
                <WhiteTextTypography align="center" variant="h2" color="inherit">
                    <Box fontWeight="900" m={1}>
                        <AnimatedNumber 
                            value={production} 
                            formatValue={formatValue} />
                        
                    </Box>
                </WhiteTextTypography>

                {/* <UpdatedTextTypography align="center" variant="body2" color="textSecondary">
                    <Moment format="YYYY-MM-DD HH:mm:ss">{updated}</Moment>
                </UpdatedTextTypography> */}
            </div>
        </div>
}

export default PowerProduction;