import { Typography, withStyles } from '@material-ui/core';
import { TabContext, TabList, TabPanel } from '@mui/lab';
import { Box, Tab } from '@mui/material';
import React, { useEffect } from 'react';
import GraphComponent from '../graph';
import SkyImage from '../skyimage';


const WhiteTextTypography = withStyles({
    root: {
      color: "#544e00",
      textShadow: "1px 1px #ffffff"
    }
  })(Typography);

  let direction = "-";
  let direction_word = "-";
  let diff = 0;

const PowerStatistics = ({production,monthGeneration,todayGeneration,updated, usage, graphData, handleSetGraph}) => {
    const [value, setValue] = React.useState('1');
    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    useEffect(() => {
        diff = Math.round(production - usage);
        if (diff > 0) {
            direction = "säljer";
            direction_word = "till";
        } else {
            direction = "köper";
            direction_word = "från";
        }
    }, [production, usage]);



    return <Box sx={{ width: '100%', typography: 'body' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange} aria-label="AJJM Soldata">
                        <Tab label="Statistik" value="1" />
                        <Tab label="Förbrukning" value="2" />
                        <Tab label="Kamera" value="3" />
                    </TabList>
                </Box>
                <TabPanel value="2" >
                    <WhiteTextTypography align="center" variant="subtitle2" color="inherit">
                        <b><p>Vi förbrukar nu&#185; {usage} W. Det betyder att vi just nu {direction} {Math.abs(diff)}&nbsp;W {direction_word} elnätet</p></b>
                        <i><p>&#185; Baserat på statistik från vårt hushålls förbrukning denna tid på dygnet och vecka på året</p></i>
                    </WhiteTextTypography>
                </TabPanel>
                <TabPanel value="1">
                    <WhiteTextTypography align="center" variant="subtitle2" color="inherit">
                        Dygnsproduktion:  {Number(todayGeneration).toFixed(0)} kWh
                    </WhiteTextTypography>
                    {/* <WhiteTextTypography align="center" variant="subtitle2" color="inherit">
                        Månadsproduktion:  {Number(monthGeneration).toFixed(0)} kWh
                    </WhiteTextTypography> */}
                    <GraphComponent graphData={graphData} updateGraph={handleSetGraph} />
                </TabPanel>
                <TabPanel value="3">
                    <SkyImage />
                </TabPanel>
            </TabContext>
            
        </Box>
}

export default PowerStatistics;