import { Box, Slider, Typography, withStyles } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import Image from 'material-ui-image'
import Moment from 'react-moment';
import moment from 'moment';


const WhiteTextTypography = withStyles({
    root: {
      color: "#544e00",
      textShadow: "1px 1px #ffffff"
    }
  })(Typography);

 

const SkyImage = () => {
    
    // const [imageName, setImageName] = useState();
    const [imageJson, setImageJson] = useState([{}]);
    const [imageSliderJson, setImageSliderJson] = useState([]);
    const [imagePos, setImagePos] = useState(0);
    const timer = useRef();

    const handleChange = (event, newValue, activeThumb) => {
        setImagePos(newValue);
        clearInterval(timer.current);
    };

    const textFormatter = (imagePos) => {
        return <Moment format='HH:mm' date={(imageJson.length > imagePos) ? imageJson[imagePos].time : "" } />;
    }


    useEffect(() => {
        fetch('./sky/sky-images.json').then(
            (res) => {
                return res.json();
            }
        ).then(
            (result => {
                setImageJson(result);
                result.map((el,pos) => {
                    setImageSliderJson([...imageSliderJson, {"value": pos, label: moment(pos.time).format('HH:mm')} ]);
                    return ""
                });
                setImagePos(result.length-1);
                timer.current = setInterval(() => {setImagePos((prevCount) => (prevCount + 1) % result.length)}
                , 1500);
            })
        )
    }, []);

    return <Box className='sky'>
            <WhiteTextTypography align="center" variant="subtitle2" color="inherit">
                Himmelskamera - <Moment format='HH:mm' date={(imageJson.length > imagePos) ? imageJson[imagePos].time : "" } />
                <Image alt="Vy av himeln" src={ (imageJson.length > imagePos) ? imageJson[imagePos].image : "" } />
                <Slider
                    value={imagePos}
                    min={0}
                    onChange={handleChange}
                    getAriaValueText={textFormatter}
                    valueLabelFormat={textFormatter}
                    max={imageJson.length-1}
                    valueLabelDisplay="auto"
                    marks={imageJson}
                />
            </WhiteTextTypography>
        </Box>   
}

export default SkyImage;