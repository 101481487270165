import { makeStyles } from '@material-ui/core';
import React from 'react';

const YoutubeComponent = () => {
        
    const useStyles = makeStyles({
        root: {
          maxWidth: 560,
          margin: "40px auto",
          aspectRatio: 16 / 9,
          width: "100%",
        }
      });
    const classes = useStyles();

    return <div className={classes.root}>
        <iframe width="100%" height="360" src="https://www.youtube.com/embed/Xm2b-vkbjTM" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </div>
}

export default YoutubeComponent;